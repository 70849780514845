@import "../TransferColorPalette.module.scss";

.title {
    font-size: 38px;
    font-weight: 700;
    text-align: left;
    padding: 0px 0 10px 0;
    color: #000000;
    margin-top: 10px;
}

.tabTitle>a {
    border: none !important;
}

.spaceBetween {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.adjustmentPageTitle {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    padding: 10px 15px;
}

.formContent {
    margin: 0 15px;
}

/* Table */
.tableCategoryNameHeader {
    font-size: 14px;
    color: #000000;
    text-align: start;
}

.tableCategoryNameSubHeader {
    font-size: 13px;
    color: #3a3a3a;
}

.tableTest>div {
    overflow-x: hidden !important;
}

.tableTest th {
    background-color: white;
    border: 1px solid black;
    text-align: left;
}

.successBadge {
    color: #005A00;
    background-color: #F2F7F2;
    border: 1px solid #005A00;
    border-radius: 4px;
    width: 50%;
}

.failedBadge {
    color: #E65C5C;
    background-color: #FFF2F2; 
    border: 1px solid #E65C5C;
    border-radius: 4px;
    width: 50%;
}

.notRunBadge {
    color: #767676;
    background-color: #F8FAFD;
    border: 1px solid #A8AAAD;
    border-radius: 4px;
    width: 50%;
}

.navigator {
    margin-top: 5vh;
    color: grey;
    width: 100%;
}

.navigator:hover {
    cursor: pointer;
}

.navigatorLink {
    text-decoration: underline;
    display: inline;
}

.navigatorLink:hover {
    color: green;
}

.hoverable:hover {
    cursor: pointer;
}

.statusSubtext {
    color: #767676;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    justify-content: start;
    display: inline-block;
    white-space: nowrap;
}

.tableRow {
    border-top: none;
    border-left: none;
    border-right: none;
}

.title {
    font-size: 28px;
    font-weight: 700;
    text-align: left;
    padding: 0px 0 10px 0;
    color: #000000;
    margin-top: 10px;
}

.tabTitle>a {
    border: none !important;
}

/* Adjustment Page */
.createButton {
    background-color: #0D5E33;
    border-color: #0D5E33;
}

.spaceBetween {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.adjustmentPageTitle {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    padding: 10px 15px;
}

.formContent {
    margin: 0 15px;
}

.tableTest>div {
    overflow-x: hidden !important;
}

.tableTest th {
    background-color: white;
    border: 1px solid black;
    text-align: left;
}

.tableTest td {
    background-color: white;
}

.tableTest table {
    background-color: white;
    border: 1px solid black;
    border-collapse: collapse;
}

.linkText {
    text-decoration: underline;
    color: darkgreen;
}

.linkText:hover {
    color: green;
    cursor: pointer;
}

.btnOk, .btnOk:hover {
    background-color: $dark-green;
    color: white;
    border-color: white;
}

.btnOk:disabled {
    background-color: #A8AAAD;
    color: white;
    border-color: #A8AAAD;
}

.btnOutlineOk {
    background-color: white;
    color: $dark-green;
    border-color: $dark-green;
}

.btnOutlineOk:hover {
    background-color: $dark-green;
    color: white;
    border-color: white;
}

.btnCancel, .btnCancel:hover {
    background-color: white;
    color: $dark-green;
    border-color: $dark-green;
}

.borderlessTable {
    width: 100%;
    border-spacing: 0;
    tr {
        border: 1px solid #eaeaea;
    }
    tr:nth-child(2n) {
      background-color: #fafafa;
    }
    th, td {
        padding-left: 1vw;
        text-align: left;
        border: none !important;
        border-color: black;
        background-color: white !important;
    }
}

.subText {
    font-size: 12px;
    color: #767676;
}

.shipmentPage {
    font-family: 'Roboto';
}

.confirmation-modal {
    .modal-content {
        border-radius: 12px;
        border: none;
        box-shadow: 0 10px 35px rgba(0, 0, 0, 0.15);
        overflow: hidden;
        text-align: center;
    }

    .modal-icon-header {
        display: flex;
        justify-content: center;
        padding-top: 2rem;

        .trash-icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70px;
            height: 70px;
            background-color: rgba(13, 94, 51, 0.1);
            color: #0D5E33;
            border-radius: 50%;

            .trash-icon {
                font-size: 28px;
            }
        }
    }

    .custom-header {
        padding: 1rem 1.5rem 0;
        border-bottom: none;

        .modal-title {
            width: 100%;
            text-align: center;
            font-weight: 700;
            font-size: 1.5rem;
            color: #333;
            margin-bottom: 0.5rem;
        }

        .close {
            position: absolute;
            right: 1rem;
            top: 1rem;
            opacity: 0.6;
            transition: all 0.2s;
            background: #f5f5f5;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                opacity: 1;
                background: #eaeaea;
                transform: rotate(90deg);
            }
        }
    }

    .custom-body {
        padding: 0.5rem 2rem 1.5rem;

        .confirmation-question {
            margin-bottom: 1.5rem;
            font-size: 1.1rem;
            font-weight: 400;
            color: #555;
            text-align: center;
        }

        .warning-container {
            display: flex;
            align-items: center;
            background-color: #FFF3F3;
            border-left: 4px solid #E65C5C;
            padding: 12px 16px;
            border-radius: 6px;
            margin: 0 auto 1rem;
            max-width: 90%;

            .warning-icon {
                color: #E65C5C;
                margin-right: 12px;
                font-size: 1.125rem;
                flex-shrink: 0;
            }

            .warning-text {
                color: #E65C5C;
                font-size: 0.9rem;
                font-weight: 500;
                line-height: 1.4;
            }
        }

        .warning-icon {
            color: #E65C5C;
            margin-right: 12px;
            font-size: 1.125rem;
            flex-shrink: 0;
        }

        .warning-text {
            color: #E65C5C;
            font-size: 0.9rem;
            font-weight: 500;
            line-height: 1.4;
        }
    }

    .custom-footer {
        padding: 1.25rem 2rem 2rem;
        border-top: none;

        .button-group {
            display: flex;
            justify-content: center;
            gap: 16px;
            width: 100%;
        }

        .btnCancel, .btnCancel:hover {
            background-color: white;
            color: #0D5E33;
            border: 1px solid #0D5E33;
            min-width: 130px;
            padding: 0.75rem 1.25rem;
            border-radius: 8px;
            font-weight: 600;
            font-size: 1rem;
            transition: all 0.2s ease;
            box-shadow: none;

            &:hover {
                background-color: rgba(13, 94, 51, 0.05);
                transform: translateY(-2px);
            }

            &:active {
                transform: translateY(1px);
            }
        }

        .btnOk, .btnOk:hover {
            background-color: #0D5E33;
            color: white;
            border: none;
            min-width: 130px;
            padding: 0.75rem 1.25rem;
            border-radius: 8px;
            font-weight: 600;
            font-size: 1rem;
            transition: all 0.2s ease;
            box-shadow: 0 4px 12px rgba(13, 94, 51, 0.2);

            &:hover {
                background-color: #0a4b29;
                transform: translateY(-2px);
                box-shadow: 0 6px 15px rgba(13, 94, 51, 0.25);
            }

            &:active {
                transform: translateY(1px);
                box-shadow: 0 2px 8px rgba(13, 94, 51, 0.15);
            }
        }
    }
}